import React, { PureComponent } from 'react'
import { Outlet } from 'react-router-dom'
import { DsBox } from '@am92/react-design-system'

type MainLayoutProps = object

type MainLayoutState = {
  windowHeight: number
  windowWidth: number
}

const DEFAULT_STATE: MainLayoutState = {
  windowHeight: 0,
  windowWidth: 0
}
export default class MainLayout extends PureComponent<
  MainLayoutProps,
  MainLayoutState
> {
  state = DEFAULT_STATE

  componentDidMount(): void {
    window.addEventListener('resize', this.setWindowHeight)
    this.setWindowHeight()
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.setWindowHeight)
  }

  setWindowHeight = () => {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    if (this.state.windowWidth !== windowWidth) {
      this.setState({ windowHeight, windowWidth })
    }
  }

  render() {
    const { windowHeight } = this.state

    return (
      <DsBox sx={{ height: windowHeight }}>
        <Outlet />
      </DsBox>
    )
  }
}
